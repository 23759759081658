import { Routes, Route } from 'react-router-dom';
import './App.css';
//website
import Home from './dasboard/Home';
import AllProducts from './dasboard/AllProducts';
import CardsDetails from './dasboard/CardsDetails';
import Checkout from './dasboard/Checkout';
import Payment from './dasboard/Payment';
import ExistingCustomer from './dasboard/ExistingCustomer';
import Profiles from './dasboard/Profiles';
import About from './dasboard/About';
import Contact from './dasboard/Contact';
import MyAcount from './dasboard/MyAcount';
import PrivacyPolicy from './dasboard/PrivacyPolicy';
import ShippingReturns from './dasboard/ShippingReturns';
import TermsCondition from './dasboard/TermsCondition';
import Categoryview from './dasboard/Categoryview';


//end website
import Register from './components/Register';

//import Login from './components/Login';
import Login from './Login';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard';


import Admin from './admin/Dashboard';
import Customer from './admin/Customer';
import CategoryList from './admin/CategoryList';
import Category from './admin/Category';
import CategoryEdit from './admin/CategoryEdit';
import CategorySubList from './admin/CategorySubList';
import CategorySub from './admin/CategorySub';
import CategorySubEdit from './admin/CategorySubEdit';
import ProductList from './admin/ProductList';
import Product from './admin/Product';
import ProductEdit from './admin/ProductEdit';
import ProductView from './admin/ProductView';
import Size from './admin/SizeUnit';
import Order from './admin/Order';


import EmailVerification from './components/EmailVerification';
import ForgotPassword from './components/ForgotPassword';
import Profile from './components/Profile';
import ChangePassword from './components/ChangePassword';


function App() {
  return (
    <div className="App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/products' element={<AllProducts />} />
          <Route path='/productdetails/:id' element={<CardsDetails />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/existing-customer" element={<ExistingCustomer/>}/>
          <Route path="/profiles" element={<Profiles />} />
          <Route path="/about" element={<About/>}/>
          <Route path="/category/:id" element={<Categoryview/>}/>
          <Route path="/contact-us" element={<Contact/>}/>
          <Route path="/myaccount" element={<MyAcount/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/shipping-returns" element={<ShippingReturns/>}/>
          <Route path="/terms-condition" element={<TermsCondition/>}/>


          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/changepassword' element={ <ProtectedRoute> <ChangePassword />  </ProtectedRoute>} />
          {/* Admin Route start */}
          <Route path='/admin' element={ <ProtectedRoute> <Admin /> </ProtectedRoute>} />
          <Route path='/customer' element={ <ProtectedRoute> <Customer /> </ProtectedRoute>} />
          <Route path='/category-list' element={ <ProtectedRoute> <CategoryList /> </ProtectedRoute>} />
          <Route path='/category' element={ <ProtectedRoute> <Category /> </ProtectedRoute>} />
          <Route path='/category_edit/:id' element={ <ProtectedRoute> <CategoryEdit /> </ProtectedRoute>} />
          <Route path='/category-sub-list' element={ <ProtectedRoute> <CategorySubList /> </ProtectedRoute>} />
          <Route path='/category-sub' element={ <ProtectedRoute> <CategorySub /> </ProtectedRoute>} />
          <Route path='/sub-category_edit/:id' element={ <ProtectedRoute> <CategorySubEdit /> </ProtectedRoute>} />
          <Route path='/product_list' element={ <ProtectedRoute> <ProductList /> </ProtectedRoute>} />
          <Route path='/product' element={ <ProtectedRoute> <Product /> </ProtectedRoute>} />
          <Route path='/product_edit/:id' element={ <ProtectedRoute> <ProductEdit /> </ProtectedRoute>} />
          <Route path='/product_view/:id' element={ <ProtectedRoute> <ProductView /> </ProtectedRoute>} />
          <Route path='/size_unit' element={ <ProtectedRoute> <Size /> </ProtectedRoute>} />
          <Route path='/order_list' element={ <ProtectedRoute> <Order /> </ProtectedRoute>} />



          <Route path='/dashboard' element={ <ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
          <Route path="/email-verification" element={  <EmailVerification />} />
          <Route path="/forgotpassword" element={ <ForgotPassword />} />
          <Route path='/profile' element = { <ProtectedRoute> <Profile /> </ProtectedRoute>} />
          
        </Routes>
    </div>
  );
}

export default App;