import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import LeftSidebar from './LeftSidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { BASE_URL } from '../Config';

const ProductEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const token = localStorage.getItem('token');

    // State Management
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [productInput, setProduct] = useState({
        product_name: '',
        cate_parent_name: '',
        subcategory_id: '',
        description: '',
        size: [],
        color: '',
        price: '',
        other_info: '',
    });
    const [image, setImage] = useState([]);
    const [newImages, setNewImages] = useState([]);
    const [removedImages, setRemovedImages] = useState([]);

    // Toggle Sidebar
    const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

    // Fetch categories and product data
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch categories
                const categoryResponse = await axios.get(`${BASE_URL}/api/category_parent_list`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setCategoryList(categoryResponse.data.parent_list);

                // Fetch product data
                const productResponse = await axios.get(`${BASE_URL}/api/product_edit/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const productData = productResponse.data.product_list;
                setImage(productData.images);

                setProduct({
                    product_name: productData.product.product_name,
                    cate_parent_name: productData.product.category_id,
                    subcategory_id: productData.product.sub_category_id,
                    description: productData.product.description,
                   
                        size: Array.isArray(productData.product.size) ? productData.product.size : [],
                 
                    color: productData.product.color || '',
                    price: productData.product.price || '',
                    other_info: productData.product.other_info || '',
                });

                // Fetch subcategories based on the current category
                fetchSubCategories(productData.product.category_id);
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };
        fetchData();
    }, [id, token]);

    // Fetch subcategories
    const fetchSubCategories = async (categoryId) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/sub_category_list_single`, {
                params: { category_id: categoryId },
                headers: { Authorization: `Bearer ${token}` },
            });
            setSubCategoryList(response.data.subcategories);
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };

    // Handle changes in form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));

        // Fetch subcategories when category changes
        if (name === 'cate_parent_name') {
            fetchSubCategories(value);
        }
    };

    // Handle subcategory change
    const handleSubCategoryChange = (e) => {
        const { value } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            subcategory_id: value,
        }));
    };

    // Handle image removal
    const handleRemoveImage = (index) => {
        const updatedImages = [...image];
        const removedImage = updatedImages.splice(index, 1)[0];
        setImage(updatedImages);
        setRemovedImages((prev) => [...prev, removedImage.id]);
    };

    // Handle file changes
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setNewImages(files);
    };

    // Handle size changes
    const handleSizeChange = (e) => {
        const { value, checked } = e.target;
        setProduct((prevProduct) => {
            let updatedSizes = [...prevProduct.size];
            if (checked && !updatedSizes.includes(value)) {
                updatedSizes.push(value);
            } else if (!checked && updatedSizes.includes(value)) {
                updatedSizes = updatedSizes.filter((size) => size !== value);
            }
            return { ...prevProduct, size: updatedSizes };
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        //alert(productInput.subcategory_id);
        const formData = new FormData();
        newImages.forEach((image) => formData.append('gallery[]', image));
        removedImages.forEach((imageId) => formData.append('removed_images[]', imageId));
        formData.append('product_name', productInput.product_name);
        formData.append('cate_parent_name', productInput.cate_parent_name);
        formData.append('subcategory_id', productInput.subcategory_id);
        formData.append('description', productInput.description);
        const sizes = Array.isArray(productInput.size) ? productInput.size : [];
        sizes.forEach((size) => formData.append('size[]', size));
        formData.append('color', productInput.color);
        formData.append('price', productInput.price);
        formData.append('other_info', productInput.other_info);

        try {
            const response = await axios.post(`${BASE_URL}/api/product_update/${id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.statusCode === 200) {
                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                    text: 'Successfully updated the product',
                });
                navigate('/product_list');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: response.data.message,
                    text: 'Failed to update the product',
                });
            }
        } catch (error) {
            console.error('Error updating product:', error.response ? error.response.data : error.message);
            Swal.fire({
                icon: 'error',
                title: 'Error updating product',
                text: error.response ? error.response.data.message : error.message,
            });
        }
    };

    return (
        <>
            <div id="page-top">
                <div id="wrapper">
                    <LeftSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <div className="container-fluid">
                                <div className='col-md-10'>
                                    <div className='card'>
                                        <div className='card-header py-3' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                            <div className='row'>
                                                <div className='col-md-6 d-flex left-content-end'>
                                                    <h6 className="font-weight-bold text-primary left">Edit Product</h6>
                                                </div>
                                                <div className='col-md-6 d-flex justify-content-end'>
                                                    <i className="fa fa-arrow-left" onClick={() => navigate(-1)}></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='card-body' style={{ overflowY: 'auto' }}>
                                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-6">
                                                        <label>Product Name</label>
                                                        <input
                                                            type='text'
                                                            id="product_name"
                                                            name='product_name'
                                                            className='form-control input-lg mb-3'
                                                            value={productInput.product_name}
                                                            onChange={(e) => setProduct({ ...productInput, product_name: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Category</label>
                                                        <select
                                                            name="cate_parent_name"
                                                            value={productInput.cate_parent_name}
                                                            onChange={handleChange}
                                                            className="form-select"
                                                            
                                                        >
                                                            <option value="">Select Category</option>
                                                            {categoryList.map((category) => (
                                                                <option key={category.id} value={category.id}>
                                                                    {category.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                   
                                                </div>
                                                <div className="form-row text-left">
                                                <div className='form-group col-md-6'>
                                                <label>Body</label>
                                                <select
                                                        name="subcategory_id"
                                                        value={productInput.subcategory_id}
                                                        onChange={handleSubCategoryChange}
                                                        className="form-select"
                                                        
                                                    >
                                                        <option value="">Select Subcategory</option>
                                                        {subCategoryList.map((subcategory) => (
                                                            <option key={subcategory.id} value={subcategory.id}>
                                                                {subcategory.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Description</label>
                                                        <textarea
                                                            id="description"
                                                            name='description'
                                                            className='form-control input-lg mb-3'
                                                            value={productInput.description}
                                                            onChange={(e) => setProduct({ ...productInput, description: e.target.value })}
                                                            cols="10" rows="1"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-12">
                                                        <label>Gallery</label>
                                                        <input
                                                            type="file"
                                                            id="gallery"
                                                            name="gallery"
                                                            className="form-control input-lg mb-3"
                                                            multiple
                                                            onChange={handleFileChange}
                                                        />
                                                        {image.map((img, index) => (
                                                            <div key={index} className="image-container">
                                                                <img src={`${BASE_URL}/public/uploads/products/${img.file}`} alt="Gallery" width="100" />
                                                                <button type="button" className="remove-button" onClick={() => handleRemoveImage(index)}>
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-6" style={{display:'none'}}>
                                                        <label>Size</label>
                                                        <div>
                                                            <label style={{margin:'0 10px 0'}}>
                                                                <input type="checkbox" name="size" value="S" checked={productInput.size.includes("S")} onChange={handleSizeChange} /> S
                                                            </label>
                                                            <label style={{margin:'0 10px 0'}}>
                                                                <input type="checkbox" name="size" value="M" checked={productInput.size.includes("M")} onChange={handleSizeChange} /> M
                                                            </label>
                                                            <label style={{margin:'0 10px 0'}}>
                                                                <input type="checkbox" name="size" value="L" checked={productInput.size.includes("L")} onChange={handleSizeChange} /> L
                                                            </label>
                                                            <label style={{margin:'0 10px 0'}}>
                                                                <input type="checkbox" name="size" value="XL" checked={productInput.size.includes("XL")} onChange={handleSizeChange} /> XL
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Color</label>
                                                        <input
                                                            type='text'
                                                            id="color"
                                                            name='color'
                                                            className='form-control input-lg mb-3'
                                                            value={productInput.color}
                                                            onChange={(e) => setProduct({ ...productInput, color: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-6">
                                                        <label>Price</label>
                                                        <input
                                                            type='text'
                                                            id="price"
                                                            name='price'
                                                            className='form-control input-lg mb-3'
                                                            value={productInput.price}
                                                            onChange={(e) => setProduct({ ...productInput, price: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Other Information</label>
                                                        <textarea
                                                            id="other_info"
                                                            name='other_info'
                                                            className='form-control input-lg mb-3'
                                                            value={productInput.other_info}
                                                            onChange={(e) => setProduct({ ...productInput, other_info: e.target.value })}
                                                            cols="10" rows="3"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-primary">Update Product</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductEdit;
