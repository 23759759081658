import React, { useState, useEffect } from 'react'; // Import useState
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { BASE_URL } from '../Config';
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
const MyAccount = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("dashboard");
  const [orders, setOrders]=useState([]);
  const [userdetails, setUserdetails]=useState([]);
  const [user, setUser]=useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const recordsPerPage = 10;
  const userId=localStorage.getItem('webuser_id');
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleShow = async (id) => {
    setShow(true); // Open modal
    setLoading(true); // Show loading state

    try {
      //alert(id);
      const response = await axios.get(`${BASE_URL}/api/get_order/${id}`);
      setData(response.data.items); // Set the fetched data
      console.log(response.data.items);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  const handleClose = () => {
    setShow(false);
    setData(null); // Clear data when closing the modal
  };
  const fetchData = async (page) => {
    try {
      if(!userId)
      {
        navigate("/");
      }
      const response = await axios.get(`${BASE_URL}/api/order_by_user`, {
        params: { user_id: userId, page, limit: recordsPerPage },
      });
      setOrders(response.data.orders.data);
      //console.log(response.data)
      setTotalPages(response.data.orders.last_page); // Total pages // Assuming backend returns total pages
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/user_details`, {
          params: { user_id: userId },
        });
        setUserdetails(response.data.user);
        setUser(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUserDetails();
  }, []);
  

  // Load data on page change
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };



  return (
    <>
    {show && data && (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {loading ? (
  <p>Loading...</p>
) : data && data.length > 0 ? (
  <div className="row">
    <div className="col-md-12">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>

              <tr>
                <td>{item.pr_name}</td>
                <td>{item.item_quantity}</td>
                <td>{item.price}</td>
                <td>
                  <img src={`${BASE_URL}/public/uploads/products/${item.img}`} alt={item.img} style={{width:'100px', height:'100px'}} />
                </td>
              </tr>
              
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  </div>
) : (
  <p>No data available.</p>
)}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
      )}
      <Header />
      <section className="page-title centred">
            <div className="pattern-layer" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/background/page-title.jpeg)` }}></div>
            <div className="auto-container">
                <div className="content-box">
                    <h1>My Account</h1>
                    <ul className="bread-crumb clearfix">
                        <li><i className="flaticon-home-1"></i><a href="#">Home</a></li>
                        <li>My Account</li>
                    </ul>
                </div>
            </div>
        </section>
      <section className="tg-may-account-wrapp tg">
        <div className="inner">
          <div className="tg-account">
            {/* Account banner */}
            <div className="account-banner">
              <div className="inner-banner">
                <div className="container">
                 

                  {/* Navbar */}
                  <div className="nav-area">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {[
                        { id: "dashboard", icon: "fas fa-tachometer-alt", label: "Dashboard" },
                        { id: "my-orders", icon: "fas fa-file-invoice", label: "Orders" },
                        
                        
                      ].map((tab) => (
                        <li className="nav-item" key={tab.id}>
                          <button
                            className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
                            onClick={() => handleTabChange(tab.id)}
                          >
                            <i className={tab.icon}></i> <span>{tab.label}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Tabs Content */}
            <div className="tabs tg-tabs-content-wrapp">
              <div className="inner">
                <div className="container">
                  <div className="tab-content">
                    {activeTab === "dashboard" && (
                      <div id="dashboard">
                        {/* Dashboard Content */}
                        <div className="my-account-dashboard">
                          <div className="row">
                           
                           
                              <div className="col-md-6 mb-3" key='my-address'>
                                <div className="card">
                                  <div className="card-body text-center">
                                    <a>
                                      <img
                                        src={`https://res.cloudinary.com/templategalaxy/image/upload/v1631257421/codepen-my-account/images/notebook_psrhv5.png`}
                                        alt='Your Addresses'
                                      />
                                    </a>
                                    <h2>Address</h2>
                                    <h3>
                                    {
                                        userdetails && userdetails.ship_address
                                          ? `${userdetails.ship_address}, ${userdetails.ship_city}, ${userdetails.ship_state}, ${userdetails.ship_zip}`
                                          : 'No Details Found'
                                      }
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 mb-3" key='account-detail'>
                                <div className="card">
                                  <div className="card-body text-center">
                                    <a>
                                      <img
                                        src={`https://res.cloudinary.com/templategalaxy/image/upload/v1631257421/codepen-my-account/images/login_aq9v9z.png`}
                                        alt='Account Details'
                                      />
                                    </a>
                                    <h2>Account Details</h2>
                                    <h3>
                                    {
                                        userdetails && userdetails.name
                                          ? `${userdetails.name}, ${userdetails.email}, ${userdetails.mobile}`
                                          : 'No Details Found'
                                      }
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            
                          </div>
                        </div>
                      </div>
                    )}

                    {activeTab === "my-orders" && (
                      <div id="my-orders">
                        {/* Orders Content */}
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Order ID</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Total</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            orders && orders.length > 0 ? (
                              orders.map((order) => (
                                <tr key={order.id}>
                                  <td>{order.id}</td>
                                  <td>{order.date}</td>
                                  <td>
                                    {(() => {
                                      if (order.status === 0) {
                                        return <span className="text-warning">Pending</span>;
                                      } else if (order.status === 1) {
                                        return <span className="text-success">Completed</span>;
                                      } else if (order.status === 3) {
                                        return <span className="text-danger">Canceled</span>;
                                      } else {
                                        return <span className="text-muted">Unknown</span>;
                                      }
                                    })()}
                                  </td>
                                  <td>{order.total}</td>
                                  <td>
                                    <a onClick={() => handleShow(order.id)} className="view-order">
                                      View Order
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">No Records</td>
                              </tr>
                            )
                          }

                          </tbody>
                        </table>
                        <div className="pagination">
                          <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            Previous 
                          </button>
                          <span>
                            Page {currentPage} of {totalPages} 
                          </span> 
                          <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          >
                             Next
                          </button>
                        </div>
                      </div>
                    )}

                    {activeTab === "address" && (
                      <div id="address">
                        {/* Address Form */}
                        <form className="tg-form">
                          <div className="form-group">
                            <label htmlFor="inputAddress">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputAddress"
                              placeholder="1234 Main St"
                            />
                          </div>
                          <button type="submit" className="btn btn-primary">
                            Update
                          </button>
                        </form>
                      </div>
                    )}

                    {/* Other tabs */}
                    {/* Add similar structures for "account-details" and "logout" */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default MyAccount;