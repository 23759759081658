const initialState = {
    isAuthenticated: false,
    name: '',
    email: '',
    orders: [],
    error: null,
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
                name: action.payload.name,
                email: action.payload.email,
                error: null,
            };

        case 'LOGIN_FAILURE':
            return {
                ...state,
                error: action.payload,
            };

        case 'LOGOUT':
            return initialState;

        default:
            return state;
    }
};

export default customerReducer;
