import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DLT } from '../redux/actions/action'; // Import the delete action
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { BASE_URL } from '../Config';
import Swal from "sweetalert2";
import Button from 'react-bootstrap/Button';
import { loginCustomer } from '../redux/actions/customerActions';
import { useNavigate } from 'react-router-dom';
const Checkout = ({ onProceed }) => {
    const cartItems = useSelector((state) => state.cartreducer.carts);
    const [quantities, setQuantities] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [shippingCost, setShippingCost] = useState(50); // Example fixed shipping cost
    const [taxRate, setTaxRate] = useState(0.1); // Example tax rate (10%)
    const [finalPrice, setFinalPrice] = useState(0);
    const dispatch = useDispatch(); // Dispatch function
    const [loginData, setLoginData]=useState(true);
    
    //form submit
    
    const [formData, setFormData] = useState({
        
        first_name: "",
        last_name: "",
        emails: "",
        phone: "",
        address: "",
        town_city: "",
        state: "",
        zip: "",
        create_account: "",
        bill_ship_same:"",
        ship_address: "",
        ship_city: "",
        ship_state: "",
        ship_zip: "",
        order_note: "",
        items: [],
        total_amount:"",
        payment_mode:"",
        user_id: JSON.parse(localStorage.getItem('webuser_id')) || null, // Set user_id from localStorage
        loading: false,
      });

      const [submittedData, setSubmittedData] = useState(null);

    
      const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
      
        // Handle bill_ship_same checkbox logic
        if (name === "bill_ship_same") {
          setFormData((prevState) => {
            if (checked) {
              // Copy billing fields to shipping fields
              return {
                ...prevState,
                bill_ship_same: checked,
                ship_address: prevState.address,
                ship_city: prevState.town_city,
                ship_state: prevState.state,
                ship_zip: prevState.zip,
              };
            } else {
              // Clear shipping fields when unchecked
              return {
                ...prevState,
                bill_ship_same: checked,
                ship_address: "",
                ship_city: "",
                ship_state: "",
                ship_zip: "",
              };
            }
          });
        } else {
          // Handle other form fields
          setFormData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
          }));
        }
      };
      
      const handleSubmit = async (event) => {
        event.preventDefault();
        const paymentType=document.querySelector('input[name="payment_mode"]:checked').value;
        if(paymentType==="COD")
        {
            const items = cartItems.map((item) => ({
                item_id: item.id,
                quantity: quantities[item.id],
                price: item.price,
              }));
              const fullFormData = {
                ...formData,
                items,
                total_amount: finalPrice.toFixed(2), // Include total_amount
            };
            setSubmittedData(fullFormData);
            console.log("Form submitted:", fullFormData);
              setFormData((prevState) => ({ ...prevState, loading: true }));
    
              try {
                const response = await axios.post(`${BASE_URL}/api/order_store`, fullFormData);
                Swal.fire({
                    icon: "success",
                    title: "Order",
                    text: "Order Placed successfully",
                });
                setFormData({
                    first_name: "",
                    last_name: "",
                    emails: "",
                    phone: "",
                    address: "",
                    town_city: "",
                    state: "",
                    zip: "",
                    create_account: false,
                    bill_ship_same: false,
                    ship_address: "",
                    ship_city: "",
                    ship_state: "",
                    ship_zip: "",
                    order_note: "",
                    user_id: JSON.parse(localStorage.getItem('webuser_id')) || null, // Reset user_id
                    loading: false,
                  });
                  setQuantities({}); // Reset quantities
                  dispatch({ type: "CLEAR_CART" });
                console.log("Form submitted successfully:", response.data.info);
              } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Error submitting form",
                });
                console.error("Error submitting form:", error);
              } finally {
                setFormData((prevState) => ({ ...prevState, loading: false }));
              }

        }
        else{
            alert("online");
        }
        
       
      };

    useEffect(() => {
        // Initialize quantities and total price based on cart items
        
        const initialQuantities = {};
        let initialTotalPrice = 0;
        cartItems.forEach(item => {
            initialQuantities[item.id] = item.qnty; // Set initial quantity
            initialTotalPrice += item.qnty * item.price; // Calculate initial total price
        });
        setQuantities(initialQuantities);
        setTotalPrice(initialTotalPrice);
    }, [cartItems]);
    console.log(cartItems);

    useEffect(() => {
        // Recalculate the final price whenever total price, shipping, or tax changes
        const taxAmount = totalPrice * taxRate; 
        const finalTotalPrice = totalPrice ;
        setFinalPrice(finalTotalPrice);
    }, [totalPrice, shippingCost, taxRate]);

    const handleQuantityChange = (id, newQuantity) => {
        const updatedQuantities = { ...quantities, [id]: newQuantity };
        setQuantities(updatedQuantities);

        // Recalculate total price
        let newTotalPrice = 0;
        cartItems.forEach(item => {
            const itemQuantity = updatedQuantities[item.id] || item.qnty;
            newTotalPrice += itemQuantity * item.price;
        });
        setTotalPrice(newTotalPrice);
    };

    const handleRemove = (id) => {
        dispatch(DLT(id)); // Dispatch remove action
    };
    // Handlers to toggle modal visibility
    const [showModal, setShowModal] = useState(false);
    const handleOpen = () => setShowModal(true);
    const handleCloses = () => setShowModal(false);
    const loginScreen=()=>{
       setLoginData(false);
    };
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    

    const dispatchs = useDispatch();
    const navigate = useNavigate();
    const [message, setMessage] = useState(''); // For feedback
    const customer = useSelector((state) => state.customer);

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        const userData = { email, password };

        dispatchs(loginCustomer(userData, navigate, setMessage));
        //alert(message);
        setShowModal(false)
    };
    useEffect(() => {
        
            if(message==='unauthorized')
            {
                Swal.fire({
                    icon: "error",
                    title: "Login",
                    text: message,
                });
            }
            else if(message==='good'){
                Swal.fire({
                    icon: "success",
                    title: "Login",
                    text: message,
                });
            }
          
    }, [message]);
//console.log(quantities);
    return (
        <>
        {showModal && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="loginModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="loginModalLabel">Login</h5>
                
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    
                  <input
                  className="form-control"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                  />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="col-form-label"></label>
                    <input
                    className="form-control"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                  />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloses}
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary" onClick={handleLoginSubmit}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
            <Header />
            <form onSubmit={handleSubmit} >
            <section className="checkout-section">
                <div className="container">
                    {JSON.parse(localStorage.getItem('webuser'))?'':
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 upper-column">
                            <div className="upper-box">
                                <div className="customer single-box">Returning Customer? <Button  onClick={handleOpen}>Click here to Login</Button></div>
                               
                            </div>
                        </div>
                    </div> 
                    }
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 left-column">
                            { JSON.parse(localStorage.getItem('webuser')) ?
                             '':
                       
                        <div className="inner-box billing-form">
                                <div className="billing-info">
                                    <h4 className="sub-title">Customer Details </h4>
                                    
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>First Name*</label>
                                            <div className="field-input">
                                                
                                                <input
                                                 type="text" 
                                                 name="first_name"
                                                 value={formData.first_name}
                                                 onChange={handleChange}
                                                 />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Last Name*</label>
                                            <div className="field-input">
                                                <input 
                                                type="text" 
                                                name="last_name"
                                                value={formData.last_name}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <label>Email Address*</label>
                                            <div className="field-input">
                                                <input 
                                                type="email" 
                                                name="emails"
                                                value={formData.emails}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Phone Number*</label>
                                            <div className="field-input">
                                                <input 
                                                type="text" 
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                       
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <label>Address*</label>
                                            <div className="field-input">
                                            <textarea
                                                
                                                name="address" 
                                                className="address form-control"
                                                value={formData.address}
                                                onChange={handleChange}
                                            />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <label>Town/City*</label>
                                            <div className="field-input">
                                                <input 
                                                type="text" 
                                                name="town_city"
                                                value={formData.town_city}
                                                onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                                            <label>State*</label>
                                            <div className="select-column select-box">
                                                <select className="nice-select selectmenu" name="state" id="ui-id-2" value={formData.state}
                                                onChange={handleChange}>
                                                    <option selected="selected">Select Option</option>
                                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chandigarh">Chandigarh</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                                    <option value="Daman and Diu">Daman and Diu</option>
                                                    <option value="Delhi">Delhi</option>
                                                    <option value="Lakshadweep">Lakshadweep</option>
                                                    <option value="Puducherry">Puducherry</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Odisha">Odisha</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telangana">Telangana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="Uttarakhand">Uttarakhand</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Zip Code*</label>
                                            <div className="field-input">
                                                <input 
                                                type="text" 
                                                name="zip"
                                                value={formData.zip}
                                                onChange={handleChange}
                                                
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-6 col-md-12 col-sm-12">
                                            <div className="create-acc">
                                                <div className="custom-controls-stacked">
                                                    <label className="custom-control material-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="create_account"
                                                        className="material-control-input"
                                                        value={formData.zip}
                                                        onChange={handleChange} 
                                                    />
                                                        <span className="material-control-indicator"></span>
                                                        <span className="description">Create an Account?</span>
                                                    </label>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                               
                                </div>
                               
                            </div>
                            
                        }
                            <div className="inner-box">
                                <div className="billing-info">
                                    
                              
                                    <div className="row">
                                    
                                    <div className="col-lg-12 col-md-6 col-sm-12 form-group">
                                    <p className="ship-add">Shipping Address </p>
                                    {JSON.parse(localStorage.getItem('webuser'))?'':   
                                    <p className="ship-check-box">
                                    <div className="create-acc">
                                              <div className="custom-controls-stacked">
                                                    <label  className="custom-control material-checkbox ">
                                                    <input
                                                        type="checkbox"
                                                        name="bill_ship_same"
                                                        value={formData.bill_ship_same}
                                                        checked={formData.bill_ship_same}
                                                        onChange={handleChange} 
                                                        className="material-control-input"
                                                    />
                                                        <span className="material-control-indicator"></span>
                                                        <span className="description"> Same as billing address </span>
                                                    </label>
                                                </div> 
                                            </div>
                                            </p>
                                        }
                                        </div>
                            
                                       
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <label>Shipping Address*</label>
                                            <div className="field-input">
                                            <textarea
                                                name="ship_address" 
                                                className="address form-control"
                                                value={formData.ship_address}
                                                onChange={handleChange}
                                                required
                                            />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                            <label>Town/City*</label>
                                            <div className="field-input form-control">
                                            <input 
                                                type="text" 
                                                name="ship_city"
                                                value={formData.ship_city}
                                                onChange={handleChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>State*</label>
                                            <div className="select-column select-box">
                                            <select className="nice-select selectmenu" name="ship_state" id="ui-id-2" style={{width: '100%'}} value={formData.ship_state}
                                                onChange={handleChange}>
                                                    <option selected="selected">Select Option</option>
                                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chandigarh">Chandigarh</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                                    <option value="Daman and Diu">Daman and Diu</option>
                                                    <option value="Delhi">Delhi</option>
                                                    <option value="Lakshadweep">Lakshadweep</option>
                                                    <option value="Puducherry">Puducherry</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Odisha">Odisha</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telangana">Telangana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="Uttarakhand">Uttarakhand</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Zip Code*</label>
                                            <div className="field-input form-control">
                                            <input 
                                                type="text" 
                                                name="ship_zip"
                                                value={formData.ship_zip}
                                                onChange={handleChange} 
                                                required
                                                />
                                            </div>
                                        </div>
                                       
                                    </div>
                                
                                </div>
                                <div className="additional-info">
                                    <div className="note-book">
                                        <label>Order Notes</label>
                                        <textarea
                                            name="order_note" 
                                            className="address form-control"
                                            value={formData.order_note}
                                            onChange={handleChange}
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 right-column">
                            <div className="inner-box">
                                <div className="order-info">
                                    <h4 className="sub-title">Your Order</h4>
                                    {cartItems.length === 0 ? (
                                        <p>Your cart is empty. Please add items to your cart.</p>
                                    ) : (
                                        <div className="order-product">
                                            <ul className="order-list clearfix">
                                                <li className="title clearfix">
                                                    <p>Product</p>
                                                    <span>Total</span>
                                                </li>
                                                {cartItems.map((item, index) => (
                                                    <li key={item.id} className="single-box clearfix">
                                                         <input
                                                            type="hidden"
                                                            value={item.id}
                                                            name={`items[${index}][item_id]`}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            value={quantities[item.id]}
                                                            name={`items[${index}][quantity]`}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            value={item.price}
                                                            name={`items[${index}][price]`}
                                                        />
                                                        
                                                        <div className="single-box clearfix">
                                                            <img src={`${BASE_URL}/public/uploads/products/${item.images[0]}`} alt=""/>
                                                            <h6>{item.product_name}</h6>
                                                            <span>
                                                                Rs.{item.price} x
                                                                <input
                                                                    type="number"
                                                                    value={quantities[item.id]}
                                                                    min="1"
                                                                    onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                                                                    style={{ width: '50px', marginLeft: '10px' }}
                                                                />
                                                                <button
                                                                    onClick={() => handleRemove(item.id)}
                                                                    style={{ marginLeft: '15px', color: 'red', cursor: 'pointer' }}
                                                                >
                                                                    X
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </li>
                                                ))}
                                                <li className="order-total clearfix">
                                                    <h6>Order Total</h6>
                                                    <span>Rs. {finalPrice.toFixed(2)}</span>
                                                    
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>

                                <div className="payment-info">
                                <h4 className="sub-title">Payment Process</h4>
                                    <div className="payment-inner">
                                        <div className="option-block">
                                            <div className="custom-controls-stacked">
                                                <label className="custom-control material-checkbox">
                                                    <input type="radio" name="payment_mode" className="material-control-input" value="COD" onChange={handleChange}/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cash on Delivery.
                                                    <span className="material-control-indicator"></span>
                                                    <span className="description"></span>
                                                </label>
                                            </div>
                                            
                                        </div>
                                        <div className="option-block">
                                            <div className="custom-controls-stacked">
                                                <label className="custom-control material-checkbox">
                                                    <input type="radio" name="payment_mode" className="material-control-input" value="Online" onChange={handleChange}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Online Payment.
                                                    <span className="material-control-indicator"></span>
                                                    
                                                </label>
                                            </div>
                                        </div>
                                        <div className="btn-box">
                                        <button type="submit" className="theme-btn-two">Place Your Order <i className="flaticon-right-1"></i></button>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </form>
            <Footer />
        </>
    );
};

export default Checkout;
