import { combineReducers } from 'redux';
import customerReducer from './customerReducer';
import { cartreducer } from './reducer';

const rootReducer = combineReducers({
    cartreducer, // Cart state
    customer: customerReducer, // Customer state
});

export default rootReducer;
