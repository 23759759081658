export const loginCustomer = (userData, navigate, setMessage,referrerPath) => {
    //const BASE_URL = 'http://127.0.0.1:8000';
    const BASE_URL = 'https://eezybreezyu.com/admin';
    return async (dispatch) => {
        try {
            const response = await fetch(`${BASE_URL}/api/login`, {
                method: 'POST',
                body: JSON.stringify(userData),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const userdata = await response.json();
            //console.log(data);
            if (response.ok) {
                dispatch({
                    type: 'LOGIN_SUCCESS',
                    payload: userdata,
                });
                //console.log(token);
                localStorage.setItem('webuser', JSON.stringify(userdata.user.name));
                localStorage.setItem('webuser_id', JSON.stringify(userdata.user.id));
                setMessage('Login successful!'); // Display success message
                navigate(referrerPath || '/checkout'); 
            } else {
                dispatch({
                    type: 'LOGIN_FAILURE',
                    payload: userdata.message || 'Login failed',
                });
                setMessage(userdata.message || 'Login failed'); // Display error message
            }
        } catch (error) {
            dispatch({
                type: 'LOGIN_FAILURE',
                payload: error.message,
            });
            setMessage(error.message); // Display network or other errors
        }
    };
};
