import React from 'react'
import Header from './Header'
import Footer from './Footer'
function PrivacyPolicy() {
  return (
    <>
     <Header />
     <section className="page-title centred"  >
            <div className="pattern-layer" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/background/page-title.jpeg)`}}></div>
            <div className="auto-container">
                <div className="content-box">
                    <h1>Privacy Policy</h1>
                    <ul className="bread-crumb clearfix">
                        <li><i className="flaticon-home-1"></i><a href="index.html">Home</a></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </section>
    <section className="about-section">
      <div className="auto-container">
        <div className="row align-items-center clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 text-column">
            <div className="privacy-section">
              
              <div className="policy">
                <h5 className="intro-heading three">What Are Privacy Policy?</h5>
                <p className="policy-details">
                  At EezybreezyU, we are not only concerned about your fragrance choice but also all the personal details you share with us while shopping online.
                  We hold to the highest standards and convenience for secure transactions and the privacy of customer information in order to value the trust you place in us.
                </p>
              </div>
              
              <div className="policy">
                <h5 className="intro-heading three">Consent</h5>
                <p className="policy-details">
                  By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our Website. By mere use of the Website, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use.<br />
                  Our Privacy Policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.
                </p>
              </div>

              <div className="policy">
                <h5 className="intro-heading three">Information we collect</h5>
                <p className="policy-details">
                  When you use our Website, we collect and store your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customised experience.
                  In general, you can browse the Website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us.
                  <span className="policy-inner-text">
                    We may automatically track certain information about you based upon your behaviour on our Website. This information may include the URL that you just came from, which URL you next go to, your computer browser information, and your IP address.
                  </span>
                  <span className="policy-inner-text">
                    We collect personally identifiable information (email address, name, phone number, credit card/debit card details, etc.) from you when you set up an account with us.
                  </span>
                </p>
              </div>
              
              <div className="policy">
                <h5 className="intro-heading three">Cookies</h5>
                <p className="policy-details">
                  We use data collection devices such as “cookies” on certain pages of the Website to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. “Cookies” are small files placed on your hard drive or storage devices that assist us in providing our services.
                  <span className="policy-inner-text">
                    You are always free to decline our cookies if your browser permits, although in that case, you may not be able to use certain features on the Website and may be required to re-enter your password more frequently.
                  </span>
                </p>
              </div>

              <div className="policy">
                <h5 className="intro-heading three">How we use your information</h5>
                <p className="policy-details">
                  We use personal information to provide the services you request. We use your information to resolve disputes, troubleshoot problems, promote safe service, and as otherwise described to you at the time of collection.
                  <span className="policy-inner-text">
                    We identify and use your IP address to help diagnose problems with our server and to administer our Website. Occasionally, we may ask you to complete optional online surveys to tailor your experience on our Website.
                  </span>
                </p>
              </div>
              
              <div className="policy">
                <h5 className="intro-heading three">Sharing your personal information</h5>
                <p className="policy-details">
                  We may share personal information with our other corporate entities and affiliates. These entities and affiliates may market to you as a result of such sharing unless you explicitly opt-out. We may disclose personal information if required by law or to protect the rights, property, or personal safety of our users or the general public.
                </p>
              </div>

              <div className="policy">
                <h5 className="intro-heading three">Security Precautions</h5>
                <p className="policy-details">
                  Our Website has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Third-party ad servers or ad networks use technologies like cookies and web beacons in their respective advertisements and links that appear on EezybreezyU.
                </p>
              </div>
              
              <div className="policy">
                <h5 className="intro-heading three">Children’s Information</h5>
                <p className="policy-details">
                  EezybreezyU does not knowingly collect any personal identifiable information from children under the age of 13. If you think that your child provided this kind of information on our website, please contact us immediately, and we will remove such information from our records.
                </p>
              </div>
              
              <div className="policy">
                <h5 className="intro-heading three">Happy to help you</h5>
                <p className="policy-details">
                  If you have any questions regarding our privacy policies, you can reach out to us at info@EezybreezyU.com. We’re always there for you!
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    </>
   
  )
}

export default PrivacyPolicy