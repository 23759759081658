import React from 'react'
import Header from './Header'
import Footer from './Footer'

function About() {
  return (
    <>
    <Header />
    <section className="page-title centred">
            <div className="pattern-layer" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/page-title.jpeg)` }}></div>
            <div className="auto-container">
                <div className="content-box">
                    <h1>About Us</h1>
                    <ul className="bread-crumb clearfix">
                        <li><i className="flaticon-home-1"></i><a href="index.html">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </section>
     <section className="about-section">
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 text-column">
            <div className="text-inner">
              <h2 className="three">Company</h2>
              <p className="text-black">
                EezybreezyU is a revolutionary hanging car air freshener that provides long-lasting fragrance, eliminating unpleasant odors and creating a pleasant environment.
              </p>
              <p>
                EezybreezyU is a homegrown brand for car air fresheners. The applications include two product streams: Hanging Car Air Fresheners and Hanging Home/Office Air Fresheners.
              </p>
            </div>
            <div className="text-inner">
              <h5 className="intro-heading three">Products</h5>
              <h4 className="intro-heading">Product Development:</h4>
              <ul>
                <li>
                  <p>
                    Today’s modern lifestyles entailing unstable jobs and incomes, frequent changes in unscheduled travel, friction in relationships commonly accompanied by a wide variety of mental and physical problems ranging from stress and anxiety to headaches and digestive issues can be mitigated by the use of aromatherapy.
                  </p>
                </li>
                <li>
                  <p>
                    Aromatherapy is the practice of using essential oils for therapeutic benefit. Aromatherapy has been used for centuries. When inhaled, the scent molecules in essential oils travel from the olfactory nerves directly to the brain and especially impact the amygdala, the emotional center of the brain.
                  </p>
                </li>
                <li>
                  <p>Long-lasting Fragrance: EezybreezyU releases a delightful scent that lasts up to 30 days, ensuring your car smells fresh and inviting.</p>
                </li>
                <li>
                  <p>Multiple Fragrance Options: Choose from a wide range of captivating fragrances to suit your personal preference.</p>
                </li>
                <li>
                  <p>Easy to Use: Simply hang EezybreezyU on your rearview mirror or any other suitable location in your car.</p>
                </li>
              </ul>
            </div>
            <div className="text-inner">
              <h5 className="intro-heading three">
                Innovation plays a key role in air fresheners purchase decisions.
              </h5>
              <ul>
                <li>
                  <p>
                    EezybreezyU promises innovation by using 100% natural fragrances, avoiding the harmful side effects of synthetic fragrances like Benzene and Toluene, which disturb hormonal balance, especially in women.
                  </p>
                </li>
                <li>
                  <p>
                    Product themes are based on seasons, faith, animation, astrology, and quotes, with matching fragrances promoting destressing, happiness, positivity, and improved focus.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="policy-features">
            <h5 className="intro-heading three">Vocal for Local Ecosystem</h5>
            <ul>
              <li>
                <p>
                  Carrier oils and essential oils are 100% pure organic, sourced from local communities in Kalpi, Jhansi, and Kannauj, enabling them to be part of our success story.
                </p>
              </li>
            </ul>
          </div>

          <div className="policy">
            <h5 className="intro-heading">Environmentally Friendly</h5>
            <p className="policy-details">
              A range of products under development includes embedded flower seeds that can be planted in pots at home for environmentally healthy disposal. Our products are made with handmade organic cotton paper for 100% sustainable disposal, resulting in an eco-friendly, safe-to-use product.
            </p>
          </div>

          <div className="policy">
            <div className="policy-features">
              <h5 className="intro-heading three" style={{ marginBottom: '2rem' }}>
                For Better Health
              </h5>
              <h4 className="intro-heading">Effects of Different Types of Hormones on Our Bodies</h4>
              <ul>
                <li>
                  <p>Serotonin – Helps balance mood and promote feelings of well-being and reward.</p>
                </li>
                <li>
                  <p>Dopamine – Known as the “feel good” hormone, it plays a role in happiness, feelings, and reward.</p>
                </li>
                <li>
                  <p>Endorphins – Your body’s natural painkillers that help alleviate stress and discomfort.</p>
                </li>
                <li>
                  <p>Oxytocin – Promotes social interaction and helps foster positive emotions.</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="policy">
            <div className="policy-features">
              <h5 className="intro-heading three" style={{ marginBottom: '2rem' }}>
                Brain Chemistry & Your Mood: 4 Hormones That Promote Happiness
              </h5>
              <ul>
                <li>
                  <p>Your mood is influenced by many factors — both positive and negative.</p>
                </li>
                <li>
                  <p>Spending time with a loved one or friend can improve it, while unexpected traffic can ruin it.</p>
                </li>
                <li>
                  <p>Your mood is more complex than just situations; brain chemistry plays a role too.</p>
                </li>
                <li>
                  <p>
                    Neurotransmitters and hormones help your brain process and communicate emotions, each having specific roles that influence how we feel.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    </>
  )
}

export default About