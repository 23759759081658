import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

export default function Topbar({ toggleSidebar }) {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const isLoggedIn = localStorage.getItem('token');
    //console.log(isLoggedIn);
    
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('id');
        window.location.href = '/login';
    };

    return (
        <>
         <link href="https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i" rel="stylesheet"></link>
         <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <link href={`${process.env.PUBLIC_URL}/assets/css/sb-admin-2.min.css`} rel="stylesheet" />
        <link href={`${process.env.PUBLIC_URL}/assets/vendor/fontawesome-free/css/all.min.css`} rel="stylesheet" type="text/css"></link>
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggle" className="btn btn-link d-md-none rounded-circle mr-3" onClick={toggleSidebar}>
                <i className="fa fa-bars"></i>
            </button>
            <ul className="navbar-nav ml-auto">
                {isLoggedIn ? (
                    <li className="nav-item" style={{listStyle:"none"}}>
                        <a className="nav-link" href="/admin">Admin</a>
                    </li>
                ) : (
                    <li className="nav-item" style={{listStyle:"none"}}>
                        <a className="nav-link" href="/">Login</a>
                    </li>
                )}
                <li className="nav-item dropdown no-arrow mt-3" style={{listStyle:"none"}}>
                    <Dropdown show={isDropdownOpen} onToggle={toggleDropdown}>
                        <Dropdown.Toggle variant="link" id="userDropdown" style={{ fontSize: '15px', textDecoration: 'none', boxShadow: 'none' }}>
                            <img className="img-profile rounded-circle" src="../assets/img/undraw_profile.svg" alt='' style={{ height: '30px', width: '30px' }} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="*">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Profile
                            </Dropdown.Item>
                            <Dropdown.Item href="*">
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Settings
                            </Dropdown.Item>
                            <Dropdown.Item href="*">
                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                Activity Log
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="" onClick={handleLogout}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
        </nav>
        </>
    );
}
